.swiper {
    width: 80%;
    height: 90%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3em !important;
    border-width: 0px;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .perk-banner-logo-container {
    height: 25%;
    aspect-ratio: 1;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .perk-banner-logo {
    border-radius: 10px;
  }

  .perk-banner-bg-image {
    object-fit: cover !important;
    border-top-right-radius: 3em;
    border-bottom-right-radius: 3em;
  }

  .perk-banner-details {
    padding: 5%;
    width: 35%;
    height: 100%;
  }

  .perk-banner-description-container {
    height: 60%;
  }

  .perk-banner-description {
    font-size: 14px;
    text-align: left;
    margin-top: 10%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 6;
  }

  .perk-banner-company {
    text-align: left;
    font-weight: 500;
    margin-top: 10%;
  }

  .perk-banner-card {
    display: flex;
    flex-direction: row;
    height: 100%
  }

  .perk-banner-image-container {
    width: 65%;
    border-top-right-radius: 3em;
    border-bottom-right-radius: 3em;
  }

  @media only screen and (max-width: 992px){
    .perk-banner-card{
      flex-direction: column-reverse !important;
    }
  
    .swiper-slide {
      border-radius: 2em !important
    }
  
    .perk-banner-image-container {
      width: 100%;
      height: 60%;
    }
  
    .perk-banner-logo-container{
      height: 70%;
      margin: auto 5%;
    }
  
    .perk-banner-details {
      height: 40%;
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 3%;
    }
  
    .perk-banner-company{
      margin: 0
    }
  
    .perk-banner-description {
      font-size: 14px;
      margin-top: 3%;
      -webkit-line-clamp: 2;
    }
  
    .perk-banner-bg-image {
      border-bottom-right-radius: 0;
      border-top-left-radius: 2em;
      border-top-right-radius: 2em;
    }
  
    .swiper {
      height: 100%;
    }
  }

  @media only screen and (max-width: 768px){  
  
    .perk-banner-logo-container{
      height: 60%;
    }
  
    .perk-banner-description {
      font-size: 12px;
      -webkit-line-clamp: 2;
    }
  
    .perk-banner-bg-image {
      border-bottom-right-radius: 0;
      border-top-left-radius: 2em;
      border-top-right-radius: 2em;
    }
  
    .swiper {
      width: 90%;
      height: 85%;
    }
  }


@media only screen and (max-width: 576px){

  .swiper-slide {
    border-radius: 1.5em !important
  }

  .perk-banner-company{
    margin: 0
  }

  .perk-banner-bg-image {
    border-top-left-radius: 1.5em;
    border-top-right-radius: 1.5em;
  }

  .swiper {
    width: 100%;
  }

  .perk-banner-description {
    -webkit-line-clamp: 2;
  }
}

@media only screen and (max-height: 700px){
  .perk-banner-description {
    -webkit-line-clamp: 1;
  }
}