.title {
    color: #652a62;
    padding: 2% 5%;
    font-size: x-large;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    color: #652a62;
}

.home {
    background-color: white !important;
}