.header-tabs-container {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5vh;
    flex-direction: column;
}

.header-tabs-title {
    width: 70%;
    display: flex;
    color: #6D45BD;
}

.header-tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: 2px solid #6845BB;
    width: 70%;
    font-size: 22px;
    font-weight: 500;
}

.nav-link {
    padding: 0px !important;
    margin-right: 20px !important;
    color: #6D45BD !important
}

.nav-link:hover {
    color: #5128a3 !important
}

.nav-active {
    border-bottom: 4px solid #6845BB !important
}

@media only screen and (max-width: 992px){
    .header-tabs{
        font-size: 20px
    }
}

@media only screen and (max-width: 768px){
    .header-tabs{
        font-size: 18px
    }
}