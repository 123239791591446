.card {
    width: 90%;
    margin: 0 auto;
    background-color: #f7f7f7 !important;
    box-shadow: rgba(0, 0, 0, 0.14) inset 0px 1px 3px;
    border-radius: 15px !important;
    
}

.card-body {
    padding: 0 !important
}

.categories {
    margin-top: 1.5em;
    margin-left: 1em;
}

.card-title {
    color: #652a62 !important;
    padding: 1em 0 0 1.5em;
}

.category {
    background-color: white !important;
    color: #6845BB !important;
    border: 0px solid #652a62 !important;
    margin: 0 0 0.4em 1em;
    font-size: 13px !important;
    border-radius: 100px !important;
    font-weight: 500 !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    padding-inline: 20px !important;
}

.selected, .category:hover {
    background-color: #6845BB !important;
    color: white !important;
}

.perks-section {
    width: 100%;
    margin-top: 1em;
    height: 100%;
}

.section-title {
    padding-left: 1.5em;
    font-size: 24px;
    font-weight: 600;
}

.section-subtitle {
    padding-left: 2.3em;
}

.popular {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
}

.all-perks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.perk-tile {
    margin: 1em;
    height: 30vh;
    width: 25%;
    min-width: 18vh;
    margin-left: 2em;
    margin-bottom: 2em;
    border-radius: 25px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    background-color: white
}

.perk-tile:hover {
    cursor: pointer;
}

.large {
    width: 18vw;
    min-width: 18vw;
}

.perk-logo-container {
    position: relative;
    top: 1vh;
    right: 1vh;
}

.perk-logo-float {
    position: absolute;
    height: 7vh;
    aspect-ratio: 1;
    right: 0%;
    border-radius: 8px;
    background-color: white;
    object-fit: contain;
}

.perk-bg-image-container {
    height: 60%;
    width: 100%;
    background-color: white;
    padding: 0px;
    border-radius: 15px;
}

.perk-bg-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.perk-image {
    border-radius: 20px;
    width: 100%;
    height: 66%;
    object-fit: cover;
}

.perk-deal-container {
    width: 75%;
    margin: auto 0;
}

.perk-deal {
    font-weight: 900;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    margin: auto 0;
}

.perk-company {
    height: 40%;
    font-weight: 500;
    font-size: larger;
    margin-top: 0.7em;
    margin-left: 0.2em;
}

.huddle-perk-container {
    width: 15%;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
}

.huddle-perk {
    width: 80%;
    aspect-ratio: 1;
    object-fit: contain;
    margin-left: auto;
    display: flex;
}

.all-perks-container{
    background-color: white !important;
    border: 0px solid black !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.perk-tile-details{
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.custom-checkbox .form-check-input:checked {
    background-color: #6845BB;
    border-color: #6845BB;    
}

@media screen and (max-width: 1400px) {
    .perk-tile {
        width: 28% !important;
        margin: 5% auto;
        height: 35vh;
    }
}

@media screen and (max-width: 1200px) {
    .filters-container {
        width: 90% !important;
        padding: 1vw !important;
        border-bottom: 1px solid #652a62;
        margin: 0px auto
    }

    .perks-container {
        width: 90% !important;
        margin: 0px auto;
    }

    .perk-tile {
        width: 30% !important;
    }

}

@media screen and (max-width: 992px) {
    .filters-container {
        padding: 1.5vw !important;
    }

    .perk-tile {
        width: 40% !important;
    }

    .perk-logo-float {
        height: 8vw;
        top: 0.8vw;
        right: 0.8vw;
    }

    .perk-deal{
        font-size: 15px;
    }
}

@media screen and (max-width: 768px) {
    .filters-container {
        padding: 2vw !important;
    }

    .perks-container {
        width: 95% !important;
    }

    .perk-tile {
        width: 45% !important;
    }

    .perk-logo-float {
        height: 8vh;
        top: 1vw;
        right: 1vw;
    }

    .perk-bg-image-container{
        height: 65%
    }

    .perk-tile-details{
        height: 35%;
    }

}

@media only screen and (max-width: 576px){
    .perks-container {
        width: 100% !important;
        margin: 0px auto;
    }

    .perk-tile {
        width: 80% !important;
    }
}

@media only screen and (max-height: 700px){
    .perk-tile {
        height: 40vh;
    }

    .perk-tile-details{
        height: 42%;
    }

    .perk-bg-image-container {
        height: 58%;
    }
}