.gradient-container {
  height: 60vh;
  width: 100vw;
  background: linear-gradient(white, #ecc1f680, 50%, #d2c3f880, 85%, white);
  display: flex;
  position: relative
}

.floating-icon-container{
    position: absolute;
    aspect-ratio: 1;
    z-index: 0;
}

.lightning-icon {
    left: 5%;
    top: 5%;
    height: 10vw;
}

.bulb-icon {
    left: -4%;
    top: 35%;
    height: 10vw;
}

.cash-icon {
    left: 10%;
    bottom: 10%;
    height: 10vw;
}

.burger-icon {
    right: 5%;
    top: 5%;
    height: 10vw;
}

.pizza-icon {
    right: 0%;
    top: 35%;
    height: 10vw;
}

.price-tag-icon {
    right: 10%;
    bottom: 10%;
    height: 10vw;
}

.gradient-children{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    margin: auto;
    height: 80%;
    align-items: center;
}

@media only screen and (max-width: 1400px){
    .floating-icon-container{
        height: 30%;
    }

    .lightning-icon, .burger-icon {
        top: 15%;
        height: 12vw;
    }

    .bulb-icon, .pizza-icon {
        top: 38%;
        height: 12vw;
    }

    .price-tag-icon, .cash-icon {
        bottom: 10%;
        height: 12vw;
    }
}

@media only screen and (max-width: 1200px){
    .floating-icon-container{
        height: 25%;
    }

    .lightning-icon, .burger-icon {
        top: 10%;
        height: 12vw;
    }

    .bulb-icon, .pizza-icon {
        top: 40%;
        height: 12vw;
    }

    .price-tag-icon, .cash-icon {
        bottom: 10%;
        height: 12vw;
    }
}

@media only screen and (max-width: 992px){
    .gradient-container {
        height: 50vh;
      }

    .floating-icon-container{
        height: 23%;
    }

    .lightning-icon, .burger-icon {
        top: 13%;
        height: 10vw;
    }

    .bulb-icon, .pizza-icon {
        top: 40%;
        height: 10vw;
    }

    .price-tag-icon, .cash-icon {
        bottom: 10%;
        height: 10vw;
    }
}

@media only screen and (max-width: 768px) {

    .lightning-icon, .burger-icon {
        top: 10%;
    }

    .bulb-icon, .pizza-icon {
        top: 40%
    }

    .price-tag-icon, .cash-icon {
        bottom: 10%
    }
}

@media only screen and (max-width: 576px) {

    .floating-icon-container{
        height: 15%;
        z-index: 0;
    }

    .bulb-icon {
        left: -4%;
        top: 45%;
    }

    .pizza-icon {
        right: 0%;
        top: 45%;
    }

    .price-tag-icon {
        right: 5%
    }

    .cash-icon{
        left: 5%
    }

}