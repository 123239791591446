.modal-dialog {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.modal-content {
    border-radius: 40px !important;
    height: 80vh;
    width: 80% !important;
    margin-top: 15% !important;
}

.perk-modal-image {
    width: 100%;
    height: 40%;
    object-fit: cover;
    border-radius: 30px 30px 0 0;

}

.modal-details {
    overflow-y: scroll;
    border-top: 1px solid #F3F3F3;
    width: 90%;
    margin: 0 auto;
    margin-top: 5%;
}

.modal-deal {
    text-align: center;
    width: 90%;
    font-size: 22px;
    font-weight: bold;
    margin: 0 auto;
    padding-top: 3%;
}

.modal-redeem-route{
    text-align: center;
    width: 80%;
    margin: 0 auto;
    color: #6845BB;
    font-size: 18px
}

.modal-description {
    margin: 5% 0%;
    font-size: 16px;
    padding: 0 5%;
}

.modal-redeem-title {
    font-size: 16px
}

.modal-redeem-description {
    font-size: 14px
}

.perk-modal-details {
    overflow-y: scroll;
}

.redeem {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 90%;
    padding-bottom: 5%;
    border-top: 1px solid #F3F3F3;
    margin: 0 auto;
}

.redeem-message {
    margin-top: 10%;
    width: 60%;
    height: 4vh;
    margin: 4vh 0 1vh;
    display: flex !important;
    flex-direction: row;
    align-items: center !important;
    justify-content: center !important;
    font-weight: 600 !important;
    color: #6845BB !important;
    text-decoration: none !important;
    background-color: #ecc1f680;
    border-radius: 8px
}

.modal-redeem-container{
    border-top: 1px solid #F3F3F3;
    padding: 3% 5%;
    margin: 0 auto;
    margin-bottom: 2%
}

.modal-redeem-section {
    margin: 0 auto;
}

.modal-button {
    width: 50%;
    height: 4vh;
    margin: 4vh 0 1vh;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-weight: 600 !important;
    color: white !important;
    text-decoration: none !important;
    background-color: #6845BB !important;
}

.single-perk-logo-container {
    position: relative;
    top: -7vh;
    justify-content: center;
    display: flex
}

.single-perk-logo-float{
    position: absolute;
    height: 14vh;
    aspect-ratio: 1;
    border-radius: 16px;
    background-color: white;
    object-fit: contain;
}

.modal-backdrop {
    opacity: 0.75 !important
}

.modal-action-section {
    width: 90%;
    margin: 3% auto
}

.button-text {
    margin: 0 auto;
    justify-content: center;
    font-size: 15px !important;
}

@media only screen and (max-width: 992px){

    .button-text {
        font-size: 14px
    }
    
    .modal-deal {
        font-size: 20px;
    }

    .modal-back {
        height: 85%;
    }

    .single-perk-logo-float {
        height: 12vh;
    }

    .single-perk-logo-container{
        top: -6vh;
    }
}

@media only screen and (max-width: 768px){
    .modal-content {
        height: 80vh;
        width: 75% !important;
    }

    .modal-back {
        height: 85%;
    }

    .single-perk-logo-float {
        height: 12vh;
    }

    .single-perk-logo-container{
        top: -6vh;
    }

    .modal-button {
        width: 60%
    }
}

@media only screen and (max-width: 500px){
    .modal-content {
        height: 70vh;
        width: 75% !important;
    }

    .modal-deal {
        font-size: 16px;
    }

    .modal-redeem-route, .modal-description, .modal-redeem-title {
        font-size: 14px
    }

    .modal-redeem-description {
        font-size: 12px
    }

    .modal-back {
        height: 70%;
    }

    .single-perk-logo-float {
        height: 11vh;
    }

    .single-perk-logo-container{
        top: -5.5vh;
    }
}

@media only screen and (max-height: 700px){
    .modal-content {
        height: 85vh;
    }

    .modal-button {
        height: 5vh !important;
        font-size: 14px !important;
    }
}